import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from '@mui/icons-material/Lock';
import { monFetch } from "../../inc/inc";

export default function Comp(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errore, setErrore] = useState(false);

  async function login(e) {
    e.preventDefault();
    const res = await monFetch("/login", {
      email,
      password,
    });
    // console.log(res);
    if (res.success) {
      Cookies.set("token", res.token, { expires: 7 });
      Cookies.set("id", res.id, { expires: 7 });
      window.location.href = "/admin/";
    } else {
      setErrore(true);
    }
  }

 
  useEffect(() => {
  }, []);

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent:"center"}}>
      <div className="boxlogin">
        <img src="/img/logo.png" />
        <br />
        <br />
        {errore && (
          <Alert severity="error" sx={{ mb: 2 }}>
            Credenziali errate
          </Alert>
        )}
        <form onSubmit={(e) => login(e)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                className="email"
                label="Email"
                type="email"
                value={email}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className="password"
                label="Password"
                type="password"
                value={password}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" fullWidth type="submit">
                Accedi
              </Button>
              <p style={{ textAlign: "center" }}>
                <a href="/admin/recupera-password">
                  <AccountCircleIcon
                    style={{ verticalAlign: "middle", marginRight: "5px" }}
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    Password Dimenticata
                  </span>
                </a>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
