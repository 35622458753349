import * as React from "react";
import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Cookies from "js-cookie";
import Alert from "@mui/material/Alert";
import Input from "@mui/material/Input";
import { flexbox } from "@mui/system";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DragHandleIcon from "@mui/icons-material/DragHandle";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import EmailIcon from "@mui/icons-material/Email";
import { monFetch } from "../../inc/inc";

export default function Comp(props) {
  const [email, setEmail] = useState("");
  const [errore, setErrore] = useState(false);
  const [mexErrore, setMexErrore] = useState(null);
  const [mexOk, setMexOk] = useState(null);

  const [segnoOperazione, setSegnoOperazione] = useState(null);
  const [primoValore, setPrimoValore] = useState("");
  const [secondoValore, setSecondoValore] = useState("");
  const [risultatoCaptcha, setRisultatoCaptcha] = useState("");

  async function checkCaptcha(e) {
    e.preventDefault();
    const res = await monFetch("/forgot", {
      valore1: primoValore,
      valore2: secondoValore,
      operazione: segnoOperazione,
      risultato: risultatoCaptcha,
      email: email,
    });
    // console.log(res);

    if (!res.success) {
      setErrore(true);
      setMexOk(false);
      setMexErrore(res.messaggio);
    } else {
      setErrore(false);
      setMexOk(res.messaggio);
    }
  }

  function captcha_init() {
    var a = Math.floor(Math.random() * 10) + 11;
    var b = Math.floor(Math.random() * 10) + 1;
    var c = Math.floor(Math.random() * 2);

    setPrimoValore(a);
    setSecondoValore(b);

    if (c == 0) {
      setSegnoOperazione(0);
    } else {
      setSegnoOperazione(1);
    }
  }


  useEffect(() => {
    captcha_init();
  }, []);

  return (
    <div style={{ minHeight: "100vh", display: "flex", alignItems: "center", justifyContent:"center"}}>
      <div className="boxlogin">
        <a href="/login">
          <img src="/img/logo.png" />
        </a>
        <form onSubmit={(e) => checkCaptcha(e)}>
          <Grid container spacing={2}>
            {!mexOk && (
              <Grid item xs={12}>
                <h2 style={{ marginTop: 0 }}>Recupera Password</h2>
                <TextField
                  className="email"
                  label="email"
                  type="email"
                  value={email}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            )}
            {!mexOk && (
              <Grid item xs={12}>
                <div className="messages"></div>

                <div className="form-inline captcha">
                  <span>Dimostra di non essere una macchina!</span>
                  <br />
                  <Input
                    name="frm-check-a"
                    value={primoValore}
                    variant="filled"
                    id="frm-check-a"
                    readOnly={true}
                    style={{ width: "50px" }}
                  />
                  <span id="frm-check-c-span">
                    {segnoOperazione == 0 ? (
                      <RemoveIcon style={{ verticalAlign: "middle" }} />
                    ) : (
                      <AddIcon style={{ verticalAlign: "middle" }} />
                    )}
                  </span>
                  <Input
                    name="frm-check-b"
                    value={secondoValore}
                    variant="filled"
                    id="frm-check-b"
                    readOnly={true}
                    style={{ width: "50px" }}
                  />
                  <span>
                    <DragHandleIcon style={{ verticalAlign: "middle" }} />
                  </span>
                  <Input
                    name="frm-check-res"
                    value={risultatoCaptcha}
                    variant="filled"
                    onChange={(e) => setRisultatoCaptcha(e.target.value)}
                    id="frm-check-res"
                    style={{ width: "50px" }}
                    required={true}
                    data-msg-required="Campo di controllo obbligatorio"
                  />
                  <label
                    id="frm-check-res-error"
                    htmlFor="frm-check-res"
                    className="error invalid-feedback"
                  ></label>
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              {errore && (
                <>
                  <br />
                  <Alert severity="error" sx={{ mb: 2 }}>
                    {mexErrore}
                  </Alert>
                </>
              )}
              {mexOk && (
                <>
                  <br />
                  <Alert severity="success" sx={{ mb: 2 }}>
                    <span dangerouslySetInnerHTML={{ __html: mexOk }}></span>
                  </Alert>
                </>
              )}
              {!mexOk && (
                <Button variant="contained" type="submit" fullWidth>
                  Invia Mail di recupero
                </Button>
              )}
            </Grid>
            <Grid item xs={12}>
              <p style={{ textAlign: "center" }}>
                <a href="/admin/login">
                  <AccountCircleIcon
                    style={{ verticalAlign: "middle", marginRight: "5px" }}
                  />
                  <span style={{ verticalAlign: "middle" }}>
                    Torna al login
                  </span>
                </a>
              </p>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
}
