import Switch from "@mui/material/Switch";

export default function Comp(props) {

    return (
        <Switch
        checked={props.privacy}
        onChange={(event)=>props.setPrivacy(event.target.checked)}
        className={props.className}
      />

    );
}