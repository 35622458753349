export default function Comp(props) {
  return (
    <>
      <input
        type={props.type || "text"}
        className={"input_testo " + props.className}
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
        placeholder={props.placeholder}
        required = {props.required}
      />
    </>
  );
}
