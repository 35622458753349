export default function Comp(props) {
  return (
    <>
      <textarea
        rows={props.rows}
        className="input_testo text_area"
        value={props.value}
        onChange={(e) => props.setValue(e.target.value)}
        placeholder={props.placeholder}
      />
    </>
  );
}
